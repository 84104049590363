//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FCImage from '~/components/FCImage.vue';

export default {
  name: 'WeMakeItFun',
  components: {
    FCImage,
  },
};
